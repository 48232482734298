import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`These are the built in components that you’ll have access to simply by using
markdown. The whitespace around these components `}<strong parentName="p">{`is significant`}</strong>{`. If you
encounter any errors, make sure you format the markdown and surounding space
properly.`}</p>
      <p>{`For most pages, we recommend starting with a `}<inlineCode parentName="p">{`PageDescription`}</inlineCode>{` followed by
`}<inlineCode parentName="p">{`AnchorLinks`}</inlineCode>{` if the content is long enough.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Text decoration</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Lists</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Links</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Images</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Code blocks</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Tables</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Blockquotes and citations</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Comments</AnchorLink>
    </AnchorLinks>
    <h2>{`Text decoration`}</h2>
    <p>{`Emphasis, aka italics, with `}<em parentName="p">{`asterisks`}</em>{` or `}<em parentName="p">{`underscores`}</em>{`. Strong emphasis, aka
bold, with `}<strong parentName="p">{`asterisks`}</strong>{` or `}<strong parentName="p">{`underscores`}</strong>{`. Combined emphasis with `}<strong parentName="p">{`asterisks
and `}<em parentName="strong">{`underscores`}</em></strong>{`. Strikethrough uses two tildes. `}<del parentName="p">{`Scratch this.`}</del></p>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown": true
      }}>{`Emphasis, aka italics, with _asterisks_ or _underscores_. Strong emphasis, aka
bold, with **asterisks** or **underscores**. Combined emphasis with **asterisks
and _underscores_**. Strikethrough uses two tildes. ~~Scratch this.~~
`}</code></pre>
    <h2>{`Headers`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` It’s generally considered
`}<a parentName="p" {...{
        "href": "https://webaim.org/techniques/semanticstructure/#contentstructure"
      }}>{`best practice`}</a>{`
to include just one `}<inlineCode parentName="p">{`h1`}</inlineCode>{` heading per page. This heading signals the title or
primary subject matter of the content to your audience. Because the title
component generated at the top of each page already uses an `}<inlineCode parentName="p">{`h1`}</inlineCode>{` heading, we
recommend using `}<inlineCode parentName="p">{`h2`}</inlineCode>{` tags for section headings within your content.`}</p>
    <h2>{`H2`}</h2>
    <h3>{`H3`}</h3>
    <h4>{`H4`}</h4>
    <h5>{`H5`}</h5>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`## H2

### H3

#### H4

##### H5
`}</code></pre>
    <h2>{`Lists`}</h2>
    <p><strong parentName="p">{`Unordered lists`}</strong></p>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}</li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
      <li parentName="ul">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
incididunt ut labore et`}</li>
      <li parentName="ul">{`dolore magna aliqua. Pharetra massa massa ultricies mi quis. Adipiscing enim
eu turpis egestas pretium aenean.`}</li>
      <li parentName="ul">{`Massa eget egestas purus viverra accumsan in nisl nisi.`}</li>
      <li parentName="ul">{`Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi
tristique.`}</li>
    </ul>
    <p><strong parentName="p">{`Ordered lists`}</strong></p>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}
        <ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol>
      </li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <p><strong parentName="p">{`Nested lists`}</strong></p>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}
        <ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`- Unordered list can use asterisks

* Or hyphens to create list items

- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et
- dolore magna aliqua. Pharetra massa massa ultricies mi quis. Adipiscing enim
  eu turpis egestas pretium aenean.
- Massa eget egestas purus viverra accumsan in nisl nisi.
- Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi
  tristique.
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`1. First ordered list item
2. Item with a nested item
   1. Nested list item
3. In markdown, the actual numbers don’t matter, just that it’s a number
4. In markdown, the actual numbers don’t matter, just that it’s a number
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`1. First ordered list item
1. Item with a nested item
   1. Nested list item
1. In markdown, the actual numbers don’t matter, just that it’s a number
`}</code></pre>
    <h2>{`Links`}</h2>
    <p><a parentName="p" {...{
        "href": "/components/demo"
      }}>{`I’m a local link`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com"
      }}>{`I’m a markdown link`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com",
        "title": "Carbon’s Homepage"
      }}>{`I’m a markdown link with title`}</a></p>
    <p>{`URLs and URLs in angle brackets will automatically get turned into links.
`}<a parentName="p" {...{
        "href": "http://www.example.com"
      }}>{`http://www.example.com`}</a>{` or `}<a parentName="p" {...{
        "href": "http://www.example.com"
      }}>{`http://www.example.com`}</a>{` and sometimes example.com
(but not on Github, for example).`}</p>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`[I’m a markdown link](https://www.carbondesignsystem.com)

[I’m a markdown link with title](https://www.carbondesignsystem.com 'Google’s Homepage')

[I’m a local link](/components/demo)

URLs and URLs in angle brackets will automatically get turned into links.
http://www.example.com or <http://www.example.com> and sometimes example.com
(but not on Github, for example).

Some text to show that the reference links can follow later.
`}</code></pre>
    <h2>{`Images`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB4U1cmUYP/8QAGRAAAgMBAAAAAAAAAAAAAAAAADEBAhES/9oACAEBAAEFAoc84Vd0f//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAARICH/2gAIAQEABj8CMc//xAAbEAABBAMAAAAAAAAAAAAAAAABABAhYTFRkf/aAAgBAQABPyEQZYQ7K2KCKvG//9oADAMBAAIAAwAAABBUL//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/EKf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCH/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARIVExQYH/2gAIAQEAAT8QEXeq8x6xHYlfCVHCyl5EGgN9CLs//9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Alt text goes here",
        "title": "Alt text goes here",
        "src": "/static/962da2dc621f778bde6ec0b513feb8f9/2e753/quantum.jpg",
        "srcSet": ["/static/962da2dc621f778bde6ec0b513feb8f9/69549/quantum.jpg 288w", "/static/962da2dc621f778bde6ec0b513feb8f9/473e3/quantum.jpg 576w", "/static/962da2dc621f778bde6ec0b513feb8f9/2e753/quantum.jpg 1152w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`![Alt text goes here](images/quantum.jpg)
`}</code></pre>
    <h2>{`Code blocks`}</h2>
    <p>{`You can read in depth about syntax highlighting and advanced code snippet
features on the `}<a parentName="p" {...{
        "href": "/components/code-blocks"
      }}>{`Code blocks`}</a>{` page.`}</p>
    <p>{`Inline `}<inlineCode parentName="p">{`code`}</inlineCode>{` has `}<inlineCode parentName="p">{`back-ticks around`}</inlineCode>{` it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "Title",
        "Title": true
      }}>{`Blocks of code have three back-ticks above and below.

You can specify a language by placing it after the backticks.

You can also supply a source code URL or title to go at the top of the code
block
`}</code></pre>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`Inline \`code\` has \`back-ticks around\` it.

\`\`\`markdown Title
Blocks have three back-ticks above and below. Pretend the backslashes aren’t
there.
\`\`\`
`}</code></pre>
    <p>{`You can view a list of included languages at the
`}<a parentName="p" {...{
        "href": "https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/vendor/prism/includeLangs.js"
      }}>{`react-prism-renderer repo`}</a>{`.`}</p>
    <h2>{`Tables`}</h2>
    <p>{`Colons can be used to align columns.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`There must be at least 3 dashes separating each header cell. The outer pipes (|)
are optional, and you don’t need to make the raw Markdown line up prettily. You
can also use inline Markdown.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Markdown`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Less`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pretty`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Still`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`renders`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`nicely`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`Colons can be used to align columns.

| Tables        |      Are      |   Cool |
| ------------- | :-----------: | -----: |
| col 3 is      | right-aligned | \\$1600 |
| col 2 is      |   centered    |   \\$12 |
| zebra stripes |   are neat    |    \\$1 |

There must be at least 3 dashes separating each header cell. The outer pipes (|)
are optional, and you don’t need to make the raw Markdown line up prettily. You
can also use inline Markdown.

| Markdown | Less      | Pretty     |
| -------- | --------- | ---------- |
| _Still_  | \`renders\` | **nicely** |
| 1        | 2         | 3          |
`}</code></pre>
    <h2>{`Blockquotes and citations`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Without aesthetic, design is either the humdrum repetition of familiar clichés
or a wild scramble for novelty. Without aesthetic, the computer is but a
mindless speed machine, producing effects without substance, form without
relevant content, or content without meaningful form.`}</p>
      <cite>– Paul Rand</cite>
    </blockquote>
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "path=components/markdown src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "path": "components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`> Without aesthetic, design is either the humdrum repetition of familiar clichés
> or a wild scramble for novelty. Without aesthetic, the computer is but a
> mindless speed machine, producing effects without substance, form without
> relevant content, or content without meaningful form.
>
> <cite>– Paul Rand</cite>
`}</code></pre>
    <h2>{`Comments`}</h2>
    <p>{`Comments can be added that will not display on the page, but will be visible in
the markdown source.`}</p>
    {/* Comments like this are visible in code, but are not visible on the page */}
    <h3>{`Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-markdown",
        "metastring": "src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/markdown"
      }}>{`Comments can be added that will not display on the page, but will be visible in
the markdown source.

<!-- Comments like this are visible in code, but are not visible on the page -->
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      